/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, SelectChangeEvent, Stack, useMediaQuery } from '@mui/material';
import FirstStep from './firstStep/FirstStep';
import SecondStep from './secondStep/SecondStep';
import ThirdStep from './ThirdStep';
import { DocumentData } from 'firebase/firestore';
import ForthStep from './ForthStep';
import { clientInfo, docInfo, formValues, orderInfo } from '../../utils/types';
import formConfig from './formConfig';
import ValidationScreen from './ValidationScreen';
import {
	redirectToStripePayment,
	storeFormData,
} from '../../utils/utilFuntions';
import { useCallback, useEffect, useRef, useState } from 'react';

import emailjs from '@emailjs/browser';
import { sendLogToSlack } from '../../utils/slackLogFunctions';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase-setup/firebase';

function sendDataToEmail(data: formValues) {
	const emailData = { message: JSON.stringify(data, null, 2) };
	emailjs
		.send('default_service', 'template_h2dxlts', emailData!, {
			publicKey: 'RTSZKvi6HCOYvdkil',
		})
		.then(
			() => {},
			(error) => {
				console.log('FAILED...', error.text);
			}
		);
}
const DynamicStep = ({
	values,
	setValues,
	activeStep,
	setActiveStep,
	medicationsList,
	insuranceDataList,
}: {
	values: formValues;
	setValues: React.Dispatch<React.SetStateAction<formValues>>;
	activeStep: number;
	setActiveStep: React.Dispatch<React.SetStateAction<number>>;
	medicationsList: DocumentData[] | null;
	insuranceDataList: DocumentData[] | null;
}) => {
	const [validStep, setValidStep] = useState(false);
	const [sendingData, setSendingData] = useState(false);

	const uniqueId = useRef(Date.now() + Math.random().toString(36));

	const handleSubmit = useCallback(() => {
		setSendingData(true);
		// Save the form data to session storage
		sessionStorage.setItem('formData', JSON.stringify(values));
		const filteredOrders = values.orders.filter(
			(order) => order.medicationName !== ''
		);
		const filteredValues = {
			...values,
			orders: filteredOrders,
		};

		storeFormData(filteredValues, uniqueId.current);
		sendLogToSlack(filteredValues);
		sendDataToEmail(filteredValues);
		redirectToStripePayment(uniqueId.current);
	}, [values, uniqueId]);

	const handleNext = useCallback(() => {
		if (activeStep === formConfig.length) {
			logEvent(analytics, `viewPayment`);
			handleSubmit();
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
			setValidStep(false);
		}
	}, [activeStep, handleSubmit, setActiveStep]);

	const handleBack = useCallback(() => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		setValidStep(true);
	}, [setActiveStep]);

	const handleChange = useCallback(
		(
			e: { target: { name: string; value: unknown } },
			object: clientInfo | docInfo | orderInfo
		) => {
			const { name, value } = e.target;
			const boolValue = value === 'yes' ? true : value === 'no' ? false : value;
			// Update the correct field in the object
			const updatedObject = { ...object, [name]: boolValue };

			// Use a functional update to ensure the correct previous state

			setValues((prevValues) => ({
				...prevValues,
				patient: {
					...prevValues.patient,
					...updatedObject,
				},
			}));
		},
		[setValues]
	);

	const handleChangeSelect = useCallback(
		(
			e: SelectChangeEvent<unknown>,
			object: clientInfo | docInfo | orderInfo
		) => {
			const { name, value } = e.target;

			// Update the correct field in the object
			const updatedObject = { ...object, [name]: value };

			// Use a functional update to ensure the correct previous state
			setValues((prevValues) => ({
				...prevValues,
				patient: {
					...prevValues.patient,
					...updatedObject,
				},
			}));
		},
		[setValues]
	);

	const isFirstStep = activeStep === 0;

	const [isPAP, setIsPAP] = useState(false);
	const [isMedicare, setIsMedicare] = useState<boolean>(false);
	const [numDocs, setNumDocs] = useState<number>(1);
	const matches = useMediaQuery('(max-width:991px)');

	useEffect(() => {
		values.patient.insurance.company === 'Medicare' &&
			(setIsMedicare(true), setIsPAP(true));
	}, [values.patient.insurance.company]);

	useEffect(() => {
		setValues((prev) => ({
			...prev,
			patient: {
				...prev.patient,
				isPAP: isPAP,
			},
		}));
	}, [isPAP, setValues]);
	return (
		<Stack spacing={2}>
			{activeStep === 0 && (
				<FirstStep
					isMedicare={isMedicare}
					setIsMedicare={setIsMedicare}
					setIsPAP={setIsPAP}
					values={values}
					setValues={setValues}
					setValidStep={setValidStep}
					insuranceDataList={insuranceDataList}
				/>
			)}
			{activeStep === 1 && (
				<SecondStep
					isPAP={isPAP}
					medicationsList={medicationsList}
					values={values}
					setValues={setValues}
					setValidStep={setValidStep}
				/>
			)}
			{activeStep === 2 && (
				<ThirdStep
					handleChange={handleChange}
					handleChangeSelect={handleChangeSelect}
					values={values}
					setValues={setValues}
					setValidStep={setValidStep}
				/>
			)}
			{activeStep === 3 && (
				<ForthStep
					numDocs={numDocs}
					setNumDocs={setNumDocs}
					values={values}
					setValues={setValues}
					setValidStep={setValidStep}
				/>
			)}
			{activeStep === 4 && !sendingData && (
				<ValidationScreen
					values={values}
					setValidStep={setValidStep}
					setValues={setValues}
				/>
			)}
			{activeStep === 4 && sendingData && (
				<div className='loader-s'>
					<div className='loader' />
				</div>
			)}

			<Stack
				direction={'row'}
				spacing={2}
				justifyContent={'center'}
				sx={
					matches
						? {
								position: 'fixed',
								bottom: 0,
								left: 0,
								width: '100%',
								padding: 2,
								backgroundColor: 'white',
								borderTop: '1px solid #E0E0E0',
						  }
						: undefined
				}>
				{handleBack && !isFirstStep && (
					<Button variant='outlined' color='primary' onClick={handleBack}>
						Back
					</Button>
				)}
				<Button
					variant='contained'
					color='primary'
					onClick={handleNext}
					disabled={!validStep}>
					{!validStep
						? 'Fill required fields to continue'
						: activeStep === 4
						? 'Go to Payment'
						: 'Continue'}
				</Button>
			</Stack>
		</Stack>
	);
};

export default DynamicStep;
