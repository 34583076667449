/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  MenuItem,
  Typography,
} from '@mui/material';
import { formValues } from '../../../utils/types';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import QualificationDialog from '../QualificationDialog';

function MedicareFields({
  values,
  setValues,
  errors,
  requiredCheck,
  setValidStep,
  isMedicarePartD,
  setIsMedicarePartD,
}: {
  values: formValues;
  setValues: React.Dispatch<React.SetStateAction<formValues>>;
  errors: { [key: string]: string };
  requiredCheck: (name: string, value: string) => void;
  setValidStep: React.Dispatch<React.SetStateAction<boolean>>;
  isMedicarePartD: boolean;
  setIsMedicarePartD: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [openCostDialog, setOpenCostDialog] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpenCostDialog(true);
  };

  const handleClose = () => {
    setOpenCostDialog(false);
    // setSelectedValue(value);
  };
  const qualificationDialogText = useRef<string>('without Medicare covarage');
  const handleMedicareChange = useCallback(
    (value: string | null, name: string) => {
      setValues((prev) => ({
        ...prev,
        patient: {
          ...prev.patient,
          medicare: {
            ...prev.patient.medicare,
            [name]: value,
          },
        },
      }));
    },
    [setValues]
  );
  return (
    <>
      <Grid item xs={12} md={3} key={'medicare partD'}>
        <FormControl required>
          <FormLabel id="medicare partD">Are you on Medicare Part D?</FormLabel>
          <RadioGroup
            aria-labelledby="medicare partD"
            name="partD"
            sx={{ mt: 2 }}
            value={values.patient.medicare['partD']}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleMedicareChange(e.target.value, 'partD');
              if (e.target.value === 'no') {
                setIsMedicarePartD(false);
              } else {
                setIsMedicarePartD(true);
                setValidStep(false);
              }
            }}>
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                value="no"
                control={<Radio required />}
                label="No"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
              <FormControlLabel
                value="yes"
                control={<Radio required />}
                label="Yes"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3} key={'medicare advantage'}>
        <FormControl required>
          <FormLabel id="medicare advantage">
            Are you on medicare Advantage
          </FormLabel>
          <RadioGroup
            aria-labelledby="medicare advantage"
            name="advantage"
            sx={{ mt: 2 }}
            value={values.patient.medicare['advantage']}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleMedicareChange(e.target.value, 'advantage');
              if (e.target.value === 'yes') {
                setValidStep(false);
                qualificationDialogText.current =
                  'who are  on Medicare Advantage';
                handleClickOpen();
              }
            }}>
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="No"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3} key={'Do you have Medicare Supplement'}>
        <FormControl required>
          <FormLabel id="Do you have Medicare Supplement">
            Do you have Medicare Supplement{' '}
          </FormLabel>
          <RadioGroup
            aria-labelledby="Do you have Medicare Supplement"
            name="supplement"
            sx={{ mt: 2 }}
            value={values.patient.medicare['supplement']}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleMedicareChange(e.target.value, 'supplement');
            }}>
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="No"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3} key={'Medicare enrollment year'}>
        <TextField
          sx={{
            mt: {
              xs: 2,
              sm: 4,
            },
          }}
          key={'Medicare enrollment year'}
          fullWidth
          select
          required
          name={'medicareYear'}
          value={values.patient.medicare['year'] || ''}
          label={'Medicare Enrollment Year'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleMedicareChange(e.target.value, 'year');
            requiredCheck('year', e.target.value);
          }}
          variant="outlined">
          <MenuItem value={'2024'}>2024</MenuItem>
          <MenuItem value={'2025'}>2025</MenuItem>
        </TextField>
      </Grid>
      {isMedicarePartD && (
        <>
          <Grid item xs={12}>
            <Typography variant="body1">
              {values.patient.medicare.year === '2024'
                ? 'Please fill your current Medicare information'
                : 'Please fill your 2025 Medicare information'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} key={'insurance name'}>
            <TextField
              error={errors['insuranceName'] ? true : false}
              helperText={errors['insuranceName']}
              required={isMedicarePartD}
              key={'insurance name'}
              fullWidth
              name={'insuranceName'}
              value={values.patient.medicare['insuranceName'] || ''}
              label={'Insurance Company Name'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'insuranceName');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'insurance address'}>
            <TextField
              error={errors['insuranceAddress'] ? true : false}
              helperText={errors['insuranceAddress']}
              required={isMedicarePartD}
              key={'insurance address'}
              fullWidth
              name={'insuranceAddress'}
              value={values.patient.medicare['insuranceAddress'] || ''}
              label={'Address of insurance Company'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'insuranceAddress');
                isMedicarePartD &&
                  requiredCheck('insuranceAddress', e.target.value);
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'insurance plan'}>
            <TextField
              error={errors['planName'] ? true : false}
              helperText={errors['planName']}
              required={isMedicarePartD}
              key={'insurance plan'}
              fullWidth
              name={'planName'}
              value={values.patient.medicare['planName'] || ''}
              label={'Plan Name'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'planName');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'insurance plan phone'}>
            <TextField
              error={errors['planPhone'] ? true : false}
              helperText={errors['planPhone']}
              required={isMedicarePartD}
              key={'insurance plan phone'}
              fullWidth
              name={'planPhone'}
              value={values.patient.medicare['planPhone'] || ''}
              label={'Plan Phone'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'planPhone');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'plan group'}>
            <TextField
              error={errors['groupNumber'] ? true : false}
              helperText={errors['groupNumber']}
              required={isMedicarePartD}
              key={'plan group'}
              fullWidth
              name={'groupNumber'}
              value={values.patient.medicare['groupNumber'] || ''}
              label={'Group#'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'groupNumber');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'RxBIN'}>
            <TextField
              error={errors['rxbin'] ? true : false}
              helperText={errors['rxbin']}
              required={isMedicarePartD}
              key={'RxBIN'}
              fullWidth
              name={'rxbin'}
              value={values.patient.medicare['rxbin'] || ''}
              label={'RxBIN#'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'rxbin');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'RxPCN'}>
            <TextField
              error={errors['rxpcn'] ? true : false}
              helperText={errors['rxpcn']}
              required={isMedicarePartD}
              key={'RxPCN'}
              fullWidth
              name={'rxpcn'}
              value={values.patient.medicare['rxpcn'] || ''}
              label={'RxPCN#'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'rxpcn');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'RxGroup'}>
            <TextField
              error={errors['rxgroup'] ? true : false}
              helperText={errors['rxgroup']}
              required={isMedicarePartD}
              key={'RxGroup'}
              fullWidth
              name={'rxgroup'}
              value={values.patient.medicare['rxgroup'] || ''}
              label={'RxGroup#'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'rxgroup');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'Rx Member ID'}>
            <TextField
              error={errors['rxmemberid'] ? true : false}
              helperText={errors['rxmemberid']}
              required={isMedicarePartD}
              key={'Rx Member ID'}
              fullWidth
              name={'rxmemberid'}
              value={values.patient.medicare['rxmemberid'] || ''}
              label={'Rx Member ID'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'rxmemberid');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'Medicare Part A ID'}>
            <TextField
              error={errors['partAid'] ? true : false}
              helperText={errors['partAid']}
              required={isMedicarePartD}
              key={'Medicare Part A ID'}
              fullWidth
              name={'partAid'}
              value={values.patient.medicare['partAid'] || ''}
              label={'Medicare Part A ID#'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'partAid');
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4} key={'MBI'}>
            <TextField
              error={errors['mbi'] ? true : false}
              helperText={errors['mbi']}
              required={isMedicarePartD}
              key={'MBI'}
              fullWidth
              name={'mbi'}
              value={values.patient.medicare['mbi'] || ''}
              label={'MBI#'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'mbi');
              }}
              variant="outlined"
            />
          </Grid>
        </>
      )}
      <QualificationDialog
        open={openCostDialog}
        handleClose={handleClose}
        qulificationGroup={qualificationDialogText.current}
        isEligible={false}
      />
    </>
  );
}

export default MedicareFields;
