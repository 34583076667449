/* eslint-disable no-mixed-spaces-and-tabs */
import { useContext, useEffect, useRef, useState } from 'react';
import { userDataState, ordersDataState } from '../../main';
import './ComponenetsStyles/PersonalArea.css';
import personalIcon from '../../assets/images/personal.svg';
import healthcareIcon from '../../assets/images/healthcare.svg';
import pharmaIcon from '../../assets/images/pharma.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	DocumentData,
	collection,
	doc,
	getDocs,
	query,
	where,
	updateDoc,
} from 'firebase/firestore'; // Add 'set' import
import { db } from '../../firebase-setup/firebase';
import { navigateWithQuery } from '../../utils/utilFuntions';

function PersonalArea({
	medicationList = [],
}: {
	medicationList: DocumentData[] | null;
}) {
	const nameref = useRef<HTMLSpanElement>(null);
	const { userData } = useContext(userDataState);
	sessionStorage.setItem('userData', JSON.stringify(userData));
	const { ordersData } = useContext(ordersDataState);
	const navigate = useNavigate();
	const location = useLocation();
	const { setOrdersData } = useContext(ordersDataState);
	const [orederReceived, setOrderReceived] = useState(false);
	const lastOrderDate = useRef<string>('');
	function getMedType(medName: string) {
		const med = medicationList?.find((med) => {
			return med.name === medName;
		});
		if (med) {
			return med.brand ? (
				<div className='home-med-type'>Brand Medicaiton</div>
			) : (
				<div className='home-med-type generic'>Generic Medicaiton</div>
			);
		}
		return '';
	}

	async function medicationRecieved(order: DocumentData) {
		if (!userData) return;
		// Update the order to received
		const userDocRef = doc(db, 'clients', userData.ssn.toString());
		const ordersRef = collection(userDocRef, 'orders');
		const q = query(
			ordersRef,
			where('timestamp', '==', order.timestamp) &&
				where('medicationName', '==', order.medicationName)
		);
		const querySnapshot = await getDocs(q);
		if (!querySnapshot.empty) {
			const docToUpdate = querySnapshot.docs[0];
			await updateDoc(doc(ordersRef, docToUpdate.id), {
				received: true,
			});
		}
		const ordersref = collection(userDocRef, 'orders');
		getDocs(ordersref).then((querySnapshot) => {
			const ordersData: DocumentData[] = [];
			querySnapshot.forEach((doc) => {
				ordersData.push(doc.data());
			});
			setOrdersData(ordersData);
		});
	}

	useEffect(() => {
		if (ordersData) {
			lastOrderDate.current = ordersData!
				.sort((a, b) => {
					return a.timestamp > b.timestamp ? -1 : 1;
				})[0]
				.timestamp.split('T')[0];
			const orderReceived = ordersData.some((order) => {
				return order.received === false;
			});
			setOrderReceived(orderReceived);
		}
	}, [ordersData]);

	return (
		<>
			{userData && ordersData && (
				<div className='container'>
					<h1 className='text-center margin-bottom-64'>
						Welcome Back <span ref={nameref}>{userData.fname}</span>
					</h1>

					<div className='personal-area-c'>
						<div className='personal-area stretch'>
							{orederReceived ? (
								<div className='tprx-card flexGrow'>
									<>
										<h2>
											<strong>
												Your Recent Order
												{` (${
													ordersData!
														.sort((a, b) => {
															return a.timestamp > b.timestamp ? -1 : 1;
														})[0]
														.timestamp.split('T')[0]
												})`}
											</strong>
										</h2>
										<div className='home-recent-orders'>
											{ordersData!.map((order) => {
												return order.received ===
													true ? null : order.timestamp.split('T')[0] ===
												  lastOrderDate.current ? (
													<div
														key={order.medicationName}
														className='home-recent-order-c'>
														<div className='home-recent-order-med-c'>
															{getMedType(order.medicationName)}
															<p className='home-recent-order-medname'>
																{order.medicationName}
															</p>
														</div>
														<button
															onClick={() => {
																medicationRecieved(order);
															}}
															className='cta-main logout-btn'
															style={{ marginLeft: 'auto' }}>
															Received
															<svg
																width='16'
																height='17'
																viewBox='0 0 16 17'
																fill='none'
																xmlns='http://www.w3.org/2000/svg'>
																<rect
																	x='0.5'
																	y='1.05762'
																	width='15'
																	height='15'
																	rx='7.5'
																	stroke='white'
																/>
																<path
																	d='M6.40951 10.4806L4.68744 8.75851C4.59465 8.66572 4.4688 8.61359 4.33757 8.61359C4.20634 8.61359 4.08049 8.66572 3.9877 8.75851C3.8949 8.8513 3.84277 8.97716 3.84277 9.10838C3.84277 9.17336 3.85557 9.2377 3.88044 9.29773C3.9053 9.35777 3.94175 9.41231 3.9877 9.45826L6.06212 11.5327C6.25567 11.7262 6.56832 11.7262 6.76186 11.5327L12.0124 6.28211C12.1052 6.18931 12.1574 6.06346 12.1574 5.93223C12.1574 5.80101 12.1052 5.67515 12.0124 5.58236C11.9196 5.48957 11.7938 5.43744 11.6626 5.43744C11.5313 5.43744 11.4055 5.48957 11.3127 5.58236L6.40951 10.4806Z'
																	fill='white'
																/>
															</svg>
														</button>
													</div>
												) : null;
											})}
										</div>
									</>
								</div>
							) : null}
							<div className='tprx-card flexGrow' style={{ gap: '1.6rem' }}>
								<h2>
									<strong>
										Steps for Obtaining & Tracking your Medication:
									</strong>
								</h2>
								<button
									className='cta-main tracking-btn'
									onClick={() => {
										userData.isRCS
											? navigateWithQuery(navigate, '/RCSTracking', location)
											: navigateWithQuery(navigate, '/brandTracking', location);
									}}>
									BRAND (No Cost) Medication Tracking
									<svg
										width='32'
										height='33'
										viewBox='0 0 32 33'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M14.8871 13.3546V16.2924H11.7596V19.4199H8.8212V16.2924H5.69434V13.3546H8.8212V10.2271H11.7596V13.3546H14.8871Z'
											stroke='#066890'
											strokeWidth='1.07922'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M27.1996 26.3819H4.80039C3.35523 26.3819 2.17285 25.1994 2.17285 23.7543V9.36091C2.17285 7.91574 3.35523 6.73328 4.80039 6.73328H27.1996C28.6448 6.73328 29.8271 7.91574 29.8271 9.36091V23.7543C29.8271 25.1994 28.6448 26.3819 27.1996 26.3819Z'
											stroke='#143A60'
											strokeWidth='1.07922'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M23.2061 16.8051H26.2302M23.2061 19.8247H26.2302M23.2061 22.8443H26.2302'
											stroke='#143A60'
											strokeWidth='1.07922'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</button>
								<button
									className='cta-main tracking-btn'
									onClick={() => {
										navigateWithQuery(navigate, '/genericTracking', location);
									}}>
									GENERIC (Low Cost) Medication Tracking
									<svg
										width='32'
										height='33'
										viewBox='0 0 32 33'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'>
										<mask
											id='mask0_1047_67'
											style={{ maskType: 'luminance' }}
											maskUnits='userSpaceOnUse'
											x='0'
											y='0'
											width='33'
											height='33'>
											<path
												d='M32.001 0.557617L32.001 32.5586L-1.39881e-06 32.5586L0 0.557616L32.001 0.557617Z'
												fill='white'
											/>
										</mask>
										<g mask='url(#mask0_1047_67)'>
											<path
												d='M24.8326 12.7661L21.9923 12.7661M17.3718 12.7661L14.5315 12.7661M9.91099 12.7661L7.07079 12.7661M24.9287 20.3503L22.0885 20.3503M17.468 20.3503L14.6278 20.3503M10.0072 20.3503L7.167 20.3503'
												stroke='#076890'
												strokeWidth='0.903421'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												d='M3.94555 23.2949L3.94555 9.82117C3.94555 8.54871 4.9771 7.51715 6.24957 7.51715L25.7507 7.51715C27.0231 7.51715 28.0547 8.54871 28.0547 9.82118L28.0547 23.2949C28.0547 24.5674 27.0231 25.599 25.7507 25.599L6.24957 25.599C4.9771 25.599 3.94555 24.5674 3.94555 23.2949ZM21.9927 13.8424L21.9927 11.6896C21.9927 10.9053 22.6284 10.2695 23.4128 10.2695L23.4128 10.2695C24.1972 10.2695 24.8329 10.9053 24.8329 11.6896L24.8329 13.8424C24.8329 14.6267 24.1972 15.2625 23.4128 15.2625L23.4128 15.2625C22.6284 15.2625 21.9927 14.6267 21.9927 13.8424ZM14.5319 13.8424L14.5319 11.6896C14.5319 10.9053 15.1677 10.2695 15.952 10.2695C16.7363 10.2695 17.3722 10.9053 17.3722 11.6896L17.3722 13.8424C17.3722 14.6267 16.7363 15.2625 15.952 15.2625C15.1677 15.2625 14.5319 14.6267 14.5319 13.8424ZM7.07116 13.8424L7.07116 11.6896C7.07116 10.9053 7.70694 10.2695 8.49126 10.2695C9.27558 10.2695 9.91136 10.9053 9.91136 11.6896L9.91136 13.8424C9.91136 14.6267 9.27558 15.2625 8.49126 15.2625C7.70694 15.2625 7.07116 14.6267 7.07116 13.8424ZM22.0889 21.4266L22.0889 19.2737C22.0889 18.4894 22.7247 17.8536 23.509 17.8536C24.2933 17.8536 24.9291 18.4894 24.9291 19.2737L24.9291 21.4266C24.9291 22.2109 24.2933 22.8467 23.509 22.8467C22.7247 22.8467 22.0889 22.2109 22.0889 21.4266ZM14.6281 21.4266L14.6281 19.2737C14.6281 18.4894 15.2639 17.8536 16.0482 17.8536C16.8325 17.8536 17.4683 18.4894 17.4683 19.2737L17.4683 21.4266C17.4683 22.2109 16.8325 22.8467 16.0482 22.8467C15.2639 22.8467 14.6281 22.2109 14.6281 21.4266ZM7.16737 21.4266L7.16737 19.2737C7.16737 18.4894 7.80315 17.8536 8.58747 17.8536C9.37179 17.8536 10.0076 18.4894 10.0076 19.2737L10.0076 21.4266C10.0076 22.2109 9.37179 22.8467 8.58747 22.8467C7.80315 22.8467 7.16737 22.2109 7.16737 21.4266Z'
												stroke='#153B61'
												strokeWidth='0.903421'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</g>
									</svg>
								</button>
							</div>
						</div>
						<div className='personal-area'>
							<div
								className='info-button-box'
								onClick={() => {
									navigateWithQuery(navigate, '/personalInfo', location);
								}}>
								<img src={personalIcon} alt='info' />
								<h2 className='text-center'>Update Personal Information</h2>
							</div>
							<div
								className='info-button-box'
								onClick={() => {
									navigateWithQuery(navigate, '/HealthcareProviders', location);
								}}>
								<img src={healthcareIcon} alt='info' />
								<h2 className='text-center'>
									Update Healthcare Provider Information
								</h2>
							</div>
							<div
								className='info-button-box'
								onClick={() => {
									navigateWithQuery(navigate, '/orders', location);
								}}>
								<img src={pharmaIcon} alt='info' />
								<h2 className='text-center' style={{ maxWidth: '75%' }}>
									Order New Medications
								</h2>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
export default PersonalArea;
