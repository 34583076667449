/* eslint-disable no-constant-condition */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Grid,
	TextField,
	MenuItem,
	Autocomplete,
	Typography,
	Link,
} from '@mui/material';
import { DocumentData } from 'firebase/firestore';
import {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { formValues } from '../../../utils/types';
import QualificationDialog from '../QualificationDialog';

function InsuranceFields({
	setIsPAP,
	values,
	setValues,
	setValidStep,
	insuranceDataList,
	rcsOnly,
	setRequiredInsurnaceFields,
}: {
	setIsPAP: (value: boolean) => void;
	values: formValues;
	setValues: React.Dispatch<React.SetStateAction<formValues>>;
	setValidStep: React.Dispatch<React.SetStateAction<boolean>>;
	insuranceDataList: DocumentData[] | null;
	rcsOnly: boolean;
	setRequiredInsurnaceFields: React.Dispatch<
		React.SetStateAction<{
			[key: string]: boolean;
		}>
	>;
}) {
	const [insuranceProductList, setInsuranceProductList] = useState<
		DocumentData[] | null
	>(null);
	const [insurancePlanList, setInsurancePlanList] = useState<
		DocumentData[] | null
	>(null);

	const [filteredInsuranceDataList, setFilteredInsuranceDataList] = useState<
		any[] | null
	>(null);

	const queryParams = useMemo(
		() => new URLSearchParams(window.location.search),
		[]
	);
	const loadedParams = useRef([false, false, false]);
	const filteredInsuranceList = useMemo(() => {
		if (!insuranceDataList) return null;
		let filteredPlans;
		const filteredList = insuranceDataList
			.map((insurance: any) => {
				if (!insurance.products) return;
				const filteredProducts = insurance.products
					.map((product: any) => {
						rcsOnly
							? (filteredPlans = product.plans.filter(
									(plan: any) => plan.PAP !== true
							  ))
							: (filteredPlans = product.plans);
						return { ...product, plans: filteredPlans };
					})
					.filter((product: any) => product.plans.length > 0);
				return { ...insurance, products: filteredProducts };
			})
			.filter((insurance) => insurance && insurance.products.length > 0);

		// Include an insurance named "I don't have an insurance"
		filteredList.push({ name: "I don't have an insurance" });
		return filteredList;
	}, [insuranceDataList, rcsOnly]);
	useEffect(() => {
		setFilteredInsuranceDataList(filteredInsuranceList);
	}, [filteredInsuranceList]);

	useEffect(() => {
		const insuranceProducts = filteredInsuranceDataList?.find(
			(item) => item.name === values.patient.insurance['company']
		);
		if (
			!insuranceProducts ||
			values.patient.insurance['company'] === "I don't have an insurance"
		)
			return;
		insuranceProducts.products = insuranceProducts.products.sort(
			(a: { name: string }, b: { name: string }) => {
				const nameA = a.name.toUpperCase();
				const nameB = b.name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			}
		);
		setInsuranceProductList(insuranceProducts.products);
	}, [filteredInsuranceDataList, setValues, values]);
	/** SET COMPANY FROM QUERY PARAMS **/
	useEffect(() => {
		if (!filteredInsuranceDataList || loadedParams.current[0] === true) return;

		const companyParam = queryParams.get('company');
		if (companyParam === '') return;
		if (companyParam && companyParam !== 'Medicare') {
			const companyExists = filteredInsuranceDataList.some(
				(insurance) => insurance.name === companyParam
			);
			if (companyExists) {
				setValues((prev) => ({
					...prev,
					patient: {
						...prev.patient,
						insurance: {
							...prev.patient.insurance,
							company: companyParam,
							product: '',
							plan: '',
						},
					},
				}));
				if (companyParam === "I don't have an insurance" || 'Medicare') {
					setIsPAP(true);
					setRequiredInsurnaceFields((prev) => ({
						...prev,
						product: false,
						plan: false,
					}));
				}
			}
		}
		loadedParams.current[0] = true;
	}, [
		filteredInsuranceDataList,
		queryParams,
		setIsPAP,
		setRequiredInsurnaceFields,
		setValues,
	]);

	/** UPDATE PRODUCT LIST WHEN COMPANY CHANGES **/
	useEffect(() => {
		if (
			!filteredInsuranceDataList ||
			values.patient.insurance.company === "I don't have an insurance"
		)
			return;

		const selectedCompany = values.patient.insurance.company;
		if (!selectedCompany) {
			setInsuranceProductList(null);
			return;
		}
		const companyData = filteredInsuranceDataList.find(
			(insurance) => insurance.name === selectedCompany
		);
		if (companyData && companyData.products.length) {
			const sortedProducts = companyData.products.sort((a: any, b: any) =>
				a.name.localeCompare(b.name)
			);
			setInsuranceProductList(sortedProducts);
		} else {
			setInsuranceProductList(null);
		}
	}, [
		values.patient.insurance.company,
		filteredInsuranceDataList,
		insuranceDataList,
	]);

	/** SET PRODUCT FROM QUERY PARAMS **/
	useEffect(() => {
		if (!insuranceProductList || loadedParams.current[1] === true) return;

		const productParam = queryParams.get('product');
		if (productParam === '') return;
		if (productParam) {
			const productExists = insuranceProductList.some(
				(product) => product.name === productParam
			);
			if (productExists) {
				setValues((prev) => ({
					...prev,
					patient: {
						...prev.patient,
						insurance: {
							...prev.patient.insurance,
							product: productParam,
							plan: '',
						},
					},
				}));
				if (productParam === 'All') {
					setIsPAP(true);
					setRequiredInsurnaceFields((prev) => ({
						...prev,
						plan: false,
					}));
				}
			}
		}
		loadedParams.current[1] = true;
	}, [
		insuranceProductList,
		queryParams,
		setIsPAP,
		setRequiredInsurnaceFields,
		setValues,
	]);

	/** SET PLAN FROM QUERY PARAMS **/
	useEffect(() => {
		if (!insurancePlanList || loadedParams.current[2] === true) return;
		const planParam = queryParams.get('plan');
		if (planParam === '') return;
		if (planParam) {
			const planExists = insurancePlanList.some(
				(plan) => plan.name === planParam
			);
			if (planExists) {
				setValues((prev) => ({
					...prev,
					patient: {
						...prev.patient,
						insurance: {
							...prev.patient.insurance,
							plan: planParam,
						},
					},
				}));

				const selectedPlan = insurancePlanList.find(
					(plan) => plan.name === planParam
				);
				setIsPAP(selectedPlan?.PAP === true);
			}
		}
		loadedParams.current[2] = true;
	}, [insurancePlanList, setValues, setIsPAP, queryParams]);

	useEffect(() => {
		const insurancePlans = insuranceProductList?.find(
			(item) => item.name === values.patient.insurance['product']
		);
		if (!insurancePlans) {
			setInsurancePlanList(null);
			return;
		}
		insurancePlans.plans = insurancePlans.plans.sort(
			(a: { name: string }, b: { name: string }) => {
				const nameA = a.name.toUpperCase();
				const nameB = b.name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			}
		);
		setInsurancePlanList(insurancePlans?.plans);
	}, [insuranceProductList, setValues, values]);
	const [openCostDialog, setOpenCostDialog] = useState<boolean>(false);
	const handleClickOpen = () => {
		setValidStep(false);
		setOpenCostDialog(true);
	};

	const handleClose = () => {
		setOpenCostDialog(false);
	};
	const handleInsuranceChange = useCallback(
		(value: string | null) => {
			setValues((prev) => ({
				...prev,
				patient: {
					...prev.patient,
					insurance: {
						...prev.patient.insurance,
						company: value || '',
						product: '',
						plan: '',
					},
				},
			}));
			setInsuranceProductList(null);
			setInsurancePlanList(null);
			if (value === "I don't have an insurance") {
				setIsPAP(true);
				setRequiredInsurnaceFields((prev) => ({
					...prev,
					product: false,
					plan: false,
				}));
			}
		},
		[setValues, setIsPAP, setRequiredInsurnaceFields]
	);
	const handleProductChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setValues((prev) => ({
				...prev,
				patient: {
					...prev.patient,
					insurance: {
						...prev.patient.insurance,
						product: e.target.value,
						plan: '',
					},
				},
			}));
			if (e.target.value === 'All') {
				setIsPAP(true);
				setRequiredInsurnaceFields((prev) => ({
					...prev,
					plan: false,
				}));
			}
			setInsurancePlanList(null);
		},
		[setValues, setIsPAP, setRequiredInsurnaceFields]
	);

	const handlePlanChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const selectedPlan = insurancePlanList?.find(
				(item) => item.name === e.target.value
			);
			setIsPAP(selectedPlan?.PAP === true);
			setValues((prev) => ({
				...prev,
				patient: {
					...prev.patient,
					insurance: {
						...prev.patient.insurance,
						plan: e.target.value,
					},
				},
			}));
		},
		[insurancePlanList, setIsPAP, setValues]
	);

	if (insuranceDataList === null) return null;
	return (
		<>
			<Grid item xs={12} sm={4} key={'insurance company grid'}>
				<Autocomplete
					disablePortal
					value={
						values.patient.insurance['company'] !== 'Medicare'
							? values.patient.insurance['company'] || null
							: null
					}
					options={
						rcsOnly
							? filteredInsuranceDataList?.map((option) => option.name) || []
							: insuranceDataList?.map((option) => option.name) || []
					}
					onChange={(_, value) => handleInsuranceChange(value)}
					renderInput={(params) => (
						<TextField
							{...params}
							required
							fullWidth
							name={`company`}
							value={values.patient.insurance['company'] || ''}
							label={'Insurance Company'}
							variant='outlined'
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} sm={4} key={'insurance product grid'}>
				<TextField
					key={'insurance product'}
					fullWidth
					select
					required={
						values.patient.insurance['company'] === `I don't have an insurance`
							? false
							: true
					}
					name={'product'}
					value={values.patient.insurance['product'] || ''}
					label={'Insurance Product'}
					onChange={handleProductChange}
					variant='outlined'>
					{insuranceProductList ? (
						insuranceProductList.map((item) => (
							<MenuItem key={item.name} value={item.name}>
								{item.name}
							</MenuItem>
						))
					) : (
						<MenuItem value={''}>No Products Available</MenuItem>
					)}
				</TextField>
			</Grid>
			<Grid item xs={12} sm={4} key={'insurance plan grid'}>
				<TextField
					key={'insurance plan'}
					fullWidth
					select
					required={
						(insurancePlanList && insurancePlanList.length === 1) ||
						values.patient.insurance['company'] === `I don't have an insurance`
							? false
							: true
					}
					name={'plan'}
					value={values.patient.insurance['plan'] || ''}
					label={'Insurance Plan'}
					onChange={handlePlanChange}
					variant='outlined'>
					{insurancePlanList ? (
						insurancePlanList?.map((item) => (
							<MenuItem key={item.name} value={item.name ? item.name : ''}>
								{item.name ? item.name : 'All'}
							</MenuItem>
						))
					) : (
						<MenuItem value={''}>No Plans Available</MenuItem>
					)}
				</TextField>
			</Grid>
			<Grid item xs={12} sm={4} key={'insurance policy grid'}>
				<TextField
					key={'insurance policy number'}
					fullWidth
					name={'policy'}
					value={values.patient.insurance['policy'] || ''}
					label={'Insurance Policy Number'}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setValues((prev) => ({
							...prev,
							patient: {
								...prev.patient,
								insurance: {
									...prev.patient.insurance,
									policy: e.target.value,
								},
							},
						}));
					}}
					variant='outlined'
				/>
			</Grid>

			<Grid item xs={12} key={'noInsuranceFound grid'}>
				<Typography variant='h4' gutterBottom>
					<Link onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
						I can’t find the insurance product I have in the list
					</Link>
				</Typography>
			</Grid>
			<QualificationDialog
				open={openCostDialog}
				handleClose={handleClose}
				qulificationGroup={'with more types of insurance products'}
				isEligible={false}
			/>
		</>
	);
}
export default InsuranceFields;
