/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  createFilterOptions,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { collection, DocumentData } from 'firebase/firestore';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase-setup/firebase';
import { checkEligibilty, fplCalculator } from '../../utils/utilFuntions';
import EligibilityCalculatorResults from './EligibilityCalculatorResults';
import '../../App.css';

export interface eligibilityValues {
  income: string;
  householdSize: number;
  medicationName: string;
  company: string;
  product: string;
  plan: string;
}

// Utility function for debouncing
const useDebounce = (func: Function, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunc = useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => func(...args), delay);
    },
    [func, delay]
  );

  return debouncedFunc;
};

function EligibilityCalculator() {
  const [values, setValues] = useState<eligibilityValues>({
    income: '',
    householdSize: 1,
    medicationName: '',
    company: '',
    product: '',
    plan: '',
  });
  const [incomeValue, setIncomeValue] = useState<string | null>(
    values.income.toString() || ''
  );

  const handleChange = (e: { target: { name: string; value: unknown } }) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    if (value === '' || value === undefined) return;
  };
  function handleIncomeChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value = e.target.value.replace(/\D/g, '');
    handleChange(e);
    if (value === '') {
      setValues({ ...values, income: '' });
      return;
    }
    value !== ''
      ? (value = '$' + parseInt(value).toLocaleString().replace(/\./g, ','))
      : (value = '');
    setIncomeValue(value);
  }

  const [medications, loadingMedications] = useCollection(
    collection(db, 'newMedicationsCollection'),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );
  const [medicationsList, setMedicationsList] = useState<DocumentData[] | null>(
    null
  );
  const [insuranceData, loadingInsuranceData] = useCollection(
    collection(db, 'insuranceCompanies'),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const [insuranceDataList, setInsuranceDataList] = useState<
    DocumentData[] | null
  >(null);
  const [insuranceProductList, setInsuranceProductList] = useState<
    DocumentData[] | null
  >(null);
  const [insurancePlanList, setInsurancePlanList] = useState<
    DocumentData[] | null
  >(null);

  const dropdownOptions = useRef<string[] | null>(null);
  const [isPAP, setIsPAP] = useState(false);
  const [clientFPL, setClientFPL] = useState<number>(0);
  const [scenario, setScenario] = useState('none');
  const [selectedMed, setSelectedMed] = useState<DocumentData | null>(null);

  const checkProductsEligibilty = useCallback(() => {
    if (!selectedMed) return;
    if (selectedMed.rcsOnly && isPAP) {
      setScenario('rcsOnlyMed');
      return;
    }
    if (selectedMed.rcsOnly && !isPAP) {
      setScenario('rcsOnlyMedAccepted');
      return;
    }
    if (selectedMed.brand) {
      if (values.company === 'Medicare') {
        setScenario('medicareBrand');
        return;
      }
      if (selectedMed.name === 'Xolair') {
        if (isPAP) {
          Number(values.income) < 150000
            ? setScenario('papEligible')
            : setScenario('papIncomeHigh');
          return;
        } else {
          setScenario('rcsEligible');
          return;
        }
      }

      const isntEligible = !checkEligibilty(clientFPL, selectedMed!.name);
      if (isntEligible) {
        if (isPAP) {
          setScenario('papIncomeHigh');
          return;
        } else {
          setScenario('rcsIncomeHigh');
        }
      } else {
        if (isPAP) {
          setScenario('papEligible');
          return;
        } else {
          setScenario('rcsEligible');
        }
      }
    } else {
      if (values.company === 'Medicare') {
        setScenario('medicareGeneric');
        return;
      } else {
        setScenario('generic');
        return;
      }
    }
  }, [clientFPL, isPAP, selectedMed, values.company, values.income]);

  useEffect(() => {
    if (loadingMedications) return;
    let orderdList = medications?.docs.map((doc) => doc.data());
    orderdList = orderdList?.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    setMedicationsList(orderdList!);
  }, [loadingMedications, medications]);

  useEffect(() => {
    if (!medicationsList) return;
    dropdownOptions.current = medicationsList!.map((option) => {
      return `${option.name} (${option.genericName})`;
    });
  }, [medicationsList]);
  useEffect(() => {
    if (loadingInsuranceData) return;
    let orderdList = insuranceData?.docs.map((doc) => doc.data());
    orderdList = orderdList?.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setInsuranceDataList(orderdList!);
  }, [loadingInsuranceData, insuranceData]);

  useEffect(() => {
    const insuranceProducts = insuranceDataList?.find(
      (item) => item.name === values.company
    );
    if (!insuranceProducts || values.company === "I don't have an insurance")
      return;
    insuranceProducts.products = insuranceProducts.products.sort(
      (a: { name: string }, b: { name: string }) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }
    );
    setInsuranceProductList(insuranceProducts?.products);
  }, [insuranceDataList, values]);

  useEffect(() => {
    const insurancePlans = insuranceProductList?.find(
      (item) => item.name === values.product
    );
    if (!insurancePlans) {
      setInsurancePlanList(null);
      return;
    }

    insurancePlans.plans = insurancePlans.plans.sort(
      (a: { name: string }, b: { name: string }) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }
    );
    setInsurancePlanList(insurancePlans?.plans);
  }, [insuranceProductList, values]);

  useEffect(() => {
    if (values.income === '' && values.company !== 'Medicare') return;
    setClientFPL(
      fplCalculator(
        values.company !== 'Medicare' ? values.householdSize : 1,
        values.company !== 'Medicare'
          ? parseInt(values.income.slice(1).replace(/,/g, ''))
          : 0
      )
    );
    if (values.medicationName) {
      setSelectedMed(
        medicationsList!.find(
          (item) =>
            item.name ===
            values.medicationName.slice(0, values.medicationName.indexOf(' ('))
        ) || null
      );
    }
  }, [medicationsList, values]);

  const [drawerContent, setDrawerContent] = useState<string | null>(null);
  const addValuestoParams = useCallback((values: eligibilityValues) => {
    const params = new URLSearchParams(window.location.search);
    Object.entries(values).forEach(([key, value]) => {
      if (value === '' || value === null) {
        params.delete(key);
      } else {
        params.set(key, value.toString());
      }
    });
    const newUrl = `${params.toString()}`;
    const urlForHistory = `${window.location.pathname}?${newUrl}`;
    window.history.pushState({ path: newUrl }, '', urlForHistory);
    window.parent.postMessage({ path: newUrl }, '*');
    console.log('params', newUrl);
    return params;
  }, []);

  const debouncedAddValuestoParams = useDebounce(addValuestoParams, 300);

  useEffect(() => {
    debouncedAddValuestoParams(values);
  }, [debouncedAddValuestoParams, values]);

  useEffect(() => {
    values.medicationName &&
      setDrawerContent(
        medicationsList!.find(
          (item) =>
            item.name ===
            values.medicationName.slice(0, values.medicationName.indexOf(' ('))
        )?.rcsExtraDetails
      );
    checkProductsEligibilty();
  }, [checkProductsEligibilty, medicationsList, values.medicationName]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
  });
  return (
    <>
      {medicationsList &&
      medicationsList.length > 0 &&
      insuranceDataList &&
      insuranceDataList.length > 0 ? (
        <Stack
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          sx={{
            paddingTop: {
              sm: 2,
              md: 8,
            },
          }}>
          <Stack spacing={8} className="container">
            <Typography
              variant="h1"
              textAlign={'center'}
              fontSize={{
                sm: '3rem',
                md: '4.8rem',
              }}
              color={'#153b61'}>
              Check your client's eligibility
            </Typography>
            <Stack spacing={4} alignItems={'center'}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    disablePortal
                    value={values.company || null}
                    options={insuranceDataList.map((option) => option.name)}
                    onChange={(_e, value) => {
                      values.product = '';
                      values.plan = '';
                      if (value === `I don't have an insurance`) {
                        setInsuranceProductList(null);
                        setInsurancePlanList(null);
                        setIsPAP(true);
                      }
                      const event = {
                        target: { name: 'company', value: value },
                      };
                      handleChange(event);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        name={`company`}
                        value={values.company || ''}
                        label={'Insurance Company'}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} key={'insurance product grid'}>
                  <TextField
                    key={'insurance product'}
                    fullWidth
                    select
                    required={
                      values.company === `I don't have an insurance`
                        ? false
                        : true
                    }
                    name={'product'}
                    value={values.product || ''}
                    label={'Insurance Product'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      values.plan = '';
                      !insurancePlanList
                        ? setIsPAP(insuranceProductList![0].plans[0]['PAP'])
                        : null;
                      setInsurancePlanList(null);
                    }}
                    variant="outlined">
                    {insuranceProductList ? (
                      insuranceProductList?.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={''}>No Products Available</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4} key={'insurance plan grid'}>
                  <TextField
                    key={'insurance plan'}
                    fullWidth
                    select
                    required={
                      (insurancePlanList && insurancePlanList.length === 1) ||
                      values.company === `I don't have an insurance`
                        ? false
                        : true
                    }
                    name={'plan'}
                    value={values['plan'] || ''}
                    label={'Insurance Plan'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setIsPAP(
                        insurancePlanList!.find(
                          (item) => item.name === e.target.value
                        )?.['PAP'] === true
                      );
                      // requiredCheck('plan', e.target.value);
                    }}
                    variant="outlined">
                    {insurancePlanList ? (
                      insurancePlanList?.map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.name ? item.name : ''}>
                          {item.name ? item.name : 'All'}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={''}>No Plans Available</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    id="income"
                    name="income"
                    label="Yearly Income"
                    onChange={handleIncomeChange}
                    value={values.income.toString() !== '' ? incomeValue : ''}
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    id="householdSize"
                    name="householdSize"
                    label="Household Number"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.householdSize || 1}
                    margin="normal"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack sx={{ marginTop: 2 }}>
                    <Autocomplete
                      filterOptions={filterOptions}
                      disablePortal
                      options={dropdownOptions.current || []}
                      getOptionLabel={(option) => option as string}
                      value={values.medicationName || null}
                      onChange={(_e, value) => {
                        handleChange({
                          target: { name: 'medicationName', value: value },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          fullWidth
                          name={'medicationName'}
                          value={values.medicationName || null}
                          label={'Select Medication'}
                          variant="outlined"
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
              {scenario !== 'none' && (
                <EligibilityCalculatorResults
                  values={values}
                  scenario={scenario}
                  insuranceDataList={insuranceDataList}
                  selectedMed={selectedMed}
                  drawerContent={drawerContent}
                  isPAP={isPAP}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <div className="loader-s">
          <div className="loader" />
        </div>
      )}
    </>
  );
}
export default EligibilityCalculator;
