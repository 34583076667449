/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  createFilterOptions,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { collection, DocumentData } from 'firebase/firestore';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase-setup/firebase';
import MedicareCalculatorResults from './MedicareCalculatorResults';
import '../../App.css';

export interface MedicareEligibilityValues {
  medicationName: string;
}

// Utility function for debouncing
const useDebounce = (func: Function, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunc = useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => func(...args), delay);
    },
    [func, delay]
  );

  return debouncedFunc;
};

function MedicareCalculator() {
  const [values, setValues] = useState<MedicareEligibilityValues>({
    medicationName: '',
  });

  const handleChange = (e: { target: { name: string; value: unknown } }) => {
    const { name, value } = e.target;
    console.log(name, value);
    if (value === '' || value === undefined) return;

    setValues({ ...values, [name]: value });
  };

  const [medications, loadingMedications] = useCollection(
    collection(db, 'newMedicationsCollection'),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );
  const [medicationsList, setMedicationsList] = useState<DocumentData[] | null>(
    null
  );

  const [dropdownOptions, setDropdownOptions] = useState<string[] | null>(null);
  const [scenario, setScenario] = useState('none');
  const [selectedMed, setSelectedMed] = useState<DocumentData | null>(null);

  const checkProductsEligibilty = useCallback(() => {
    if (!selectedMed) return;
    if (selectedMed.brand) {
      setScenario('medicareBrand');
      return;
    } else {
      setScenario('medicareGeneric');
      return;
    }
  }, [selectedMed]);

  useEffect(() => {
    if (loadingMedications) return;
    let orderdList = medications?.docs.map((doc) => doc.data());
    orderdList = orderdList?.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    setMedicationsList(orderdList!);
  }, [loadingMedications, medications]);

  useEffect(() => {
    if (!medicationsList) return;
    setDropdownOptions(
      medicationsList!.map((option) => {
        return `${option.name} (${option.genericName})`;
      })
    );
  }, [medicationsList]);

  useEffect(() => {
    checkProductsEligibilty();
  }, [checkProductsEligibilty, selectedMed]);

  const addValuestoParams = useCallback((values: MedicareEligibilityValues) => {
    const params = new URLSearchParams(window.location.search);
    Object.entries(values).forEach(([key, value]) => {
      if (value === '' || value === null) {
        params.delete(key);
      } else {
        params.set(key, value.toString());
      }
    });
    const newUrl = `${params.toString()}`;
    const urlForHistory = `${window.location.pathname}?${newUrl}`;
    window.history.pushState({ path: newUrl }, '', urlForHistory);
    window.parent.postMessage({ path: newUrl }, '*');
    return params;
  }, []);

  const debouncedAddValuestoParams = useDebounce(addValuestoParams, 300);

  useEffect(() => {
    debouncedAddValuestoParams(values);
  }, [debouncedAddValuestoParams, values]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
  });

  useEffect(() => {
    if (!medicationsList || !values.medicationName) return;
    setSelectedMed(
      medicationsList!.find(
        (item) =>
          item.name ===
          values.medicationName.slice(0, values.medicationName.indexOf(' ('))
      ) || null
    );
  }, [medicationsList, values.medicationName]);
  return (
    <>
      {medicationsList && medicationsList.length > 0 && dropdownOptions ? (
        <Stack
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          sx={{
            paddingTop: {
              sm: 2,
              md: 8,
            },
          }}>
          <Stack spacing={8} className="container">
            <Typography
              variant="h1"
              textAlign={'center'}
              fontSize={{
                sm: '3rem',
                md: '4.8rem',
              }}
              color={'#153b61'}>
              Check your client's eligibility
            </Typography>
            <Stack spacing={4} alignItems={'center'}>
              <Grid
                container
                spacing={2}
                width={{
                  xs: '100%',
                  md: '70rem',
                }}>
                <Grid item xs={12}>
                  <Stack>
                    <Autocomplete
                      filterOptions={filterOptions}
                      disablePortal
                      options={dropdownOptions || []}
                      getOptionLabel={(option) => option as string}
                      value={values.medicationName || null}
                      onChange={(_e, value) => {
                        handleChange({
                          target: { name: 'medicationName', value: value },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          fullWidth
                          name={'medicationName'}
                          value={values.medicationName || null}
                          label={'Select Medication'}
                          variant="outlined"
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
              {scenario !== 'none' && (
                <MedicareCalculatorResults
                  values={values}
                  scenario={scenario}
                  selectedMed={selectedMed}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <div className="loader-s">
          <div className="loader" />
        </div>
      )}
    </>
  );
}
export default MedicareCalculator;
