/* eslint-disable react-refresh/only-export-components */
import { Stack } from '@mui/material';
import FormStepper from './FormStepper';
import { collection, DocumentData } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase-setup/firebase';
import '../../App.css';
import { formConfigField } from '../../utils/types';

export const requiredFields = (field: formConfigField, value: string) => {
	if (field.required && value === '') {
		return 'This field is required';
	}
	return '';
};

function ApplyForm() {
	const [medications, loadingMedications] = useCollection(
		collection(db, 'newMedicationsCollection'),
		{
			snapshotListenOptions: { includeMetadataChanges: false },
		}
	);
	const [medicationsList, setMedicationsList] = useState<DocumentData[] | null>(
		null
	);
	useEffect(() => {
		if (loadingMedications) return;
		let orderdList = medications?.docs.map((doc) => doc.data());
		orderdList = orderdList?.sort((a, b) => {
			if (a.name > b.name) return 1;
			if (a.name < b.name) return -1;
			return 0;
		});
		setMedicationsList(orderdList!);
	}, [loadingMedications, medications]);

	const [insuranceData, loadingInsuranceData] = useCollection(
		collection(db, 'insuranceCompanies'),
		{
			snapshotListenOptions: { includeMetadataChanges: false },
		}
	);
	useEffect(() => {
		if (loadingInsuranceData) return;
		let orderdList = insuranceData?.docs.map((doc) => doc.data());
		orderdList = orderdList?.sort((a, b) => {
			const nameA = a.name.toUpperCase();
			const nameB = b.name.toUpperCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		});
		setInsuranceDataList(orderdList!);
	}, [loadingInsuranceData, insuranceData]);
	const [insuranceDataList, setInsuranceDataList] = useState<
		DocumentData[] | null
	>(null);

	return (
		<Stack
			className='container'
			sx={{
				paddingTop: {
					xs: 2,
					md: 8,
				},
			}}>
			{!loadingMedications &&
			!loadingInsuranceData &&
			medicationsList &&
			medicationsList!.length > 0 ? (
				<FormStepper
					medicationsList={medicationsList}
					insuranceDataList={insuranceDataList}
				/>
			) : (
				<div className='loader-s'>
					<div className='loader' />
				</div>
			)}
		</Stack>
	);
}

export default ApplyForm;
