/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Grid,
  TextField,
  Autocomplete,
  MenuItem,
  Typography,
  Stack,
  Button,
  Box,
  Link,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { DocumentData } from 'firebase/firestore';
import React from 'react';
import { formValues } from '../../../utils/types';
import CostDialog from './CostDialog';
import QualificationDialog from '../QualificationDialog';
import { checkEligibilty, fplCalculator } from '../../../utils/utilFuntions';
import theme from '../../../theme';

const rcsGroup1 = [
  'Adbry',
  'Admelog',
  'Basaglar',
  'Biktarvy',
  'Emgality',
  'Enbrel',
  'Epclusa',
  'Eucrisa',
  'Forteo',
  'HumaLOG',
  'Humira',
  'HumuLIN',
  'Invokana',
  'Janumet',
  'Januvia',
  'Jardiance',
  'Lantus',
  'Linzess',
  'Lyumjev',
  'Motegrity',
  'Multaq',
  'NovoLOG',
  'Nurtec',
  'Ocrevus',
  'Ozempic',
  'Qulipta',
  'Remicade',
  'Rybelsus',
  'Stiolto Respimat',
  'Symtuza',
  'Taltz',
  'Toujeo',
  'Tresiba',
  'Trintellix',
  'Ubrelvy',
  'Viberzi',
  'Zenpep',
  'Zubsolv',
  'Olumiant',
  'Fasenra',
  'Abilify Maintena',
];

function SecondStep({
  isPAP,
  medicationsList,
  values,
  setValues,
  setValidStep,
}: {
  isPAP: boolean;
  medicationsList: DocumentData[] | null;
  values: formValues;
  setValues: React.Dispatch<React.SetStateAction<formValues>>;
  setValidStep: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [medicationNum, setMedicationNum] = useState<number>(4);
  const [medicationStrengths, setMedicationStrengths] = useState<
    Array<{ strength: string; price: string }[] | null>
  >(Array(4).fill(null));

  const [medicationDialogData, setMedicationDialogData] = useState<any>(null);
  const loadedParams = useRef<boolean>(false);
  const getMedCost = useCallback(
    (medication: string, strength: string, isPAP: boolean) => {
      const med = medicationsList?.find((item) => item.name === medication);
      if (!med) return null;

      const medOption = med.options.find(
        (item: { strength: string; price: string }) =>
          item.strength === strength
      );
      if (isPAP || !med.brand) return medOption.price;

      const insurance = med.insurance.find((item: any) => {
        return item.name === values.patient.insurance.company;
      });
      const product = insurance.products.find((item: any) => {
        return item.name === values.patient.insurance.product;
      });
      const plan = product.plans.find((item: any) => {
        return item.name === values.patient.insurance.plan;
      });
      setMedicationDialogData(plan);
      return 'Read Full Cost Estimation';
    },
    [
      medicationsList,
      values.patient.insurance.company,
      values.patient.insurance.product,
      values.patient.insurance.plan,
    ]
  );
  const [medCosts, setMedCosts] = useState<Array<string> | null>(
    Array(4).fill('')
  );
  const [medicationDetailsText, setMedicationDetailsText] = useState<
    Array<string>
  >(Array(4).fill(''));
  const [openCostDialog, setOpenCostDialog] = useState<boolean>(false);
  const [openEligibilityDialog, setOpenEligibilityDialog] =
    useState<boolean>(false);

  const handleClickOpen = (type: string) => {
    type === 'eligibility'
      ? setOpenEligibilityDialog(true)
      : setOpenCostDialog(true);
  };

  const handleClose = () => {
    setOpenEligibilityDialog(false);
    setOpenCostDialog(false);
  };
  const [isFirstTextFieldChanged, setIsFirstTextFieldChanged] = useState([
    false,
    false,
    false,
    false,
  ]);
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
  });
  const clientFPL = fplCalculator(
    parseInt(values.patient.numinhouse),
    parseInt(values.patient.income as string)
  );
  const currentMedName = useRef<string | null>(null);
  const [rcsExtraFields, setRcsExtraFields] = useState<boolean>(false);
  const selectMedicationList = useMemo(() => {
    if (!medicationsList) return [];
    return medicationsList
      .filter((med) => {
        if (isPAP) {
          if (med.rcsOnly) {
            return false;
          } else {
            return true;
          }
        }
        const insurance = med.insurance?.find(
          (item: any) => item.name === values.patient.insurance.company
        );
        const product = insurance?.products?.find(
          (item: any) => item.name === values.patient.insurance.product
        );
        const plan = product?.plans?.find(
          (item: any) => item.name === values.patient.insurance.plan
        );
        return (
          plan &&
          !plan.prices.some(
            (option: any) =>
              option.name ===
              'No Real Cost Saving Program available for this Medication'
          )
        );
      })
      .map((option) => option.name + ` (${option.genericName})`);
  }, [medicationsList, isPAP, values.patient.insurance]);
  const requiredCheck = useCallback(() => {
    for (let i = 0; i < medicationNum; i++) {
      if (values.orders[i].medicationName === '' && i === 0) {
        setValidStep(false);
        return;
      } else if (
        values.orders[i].medicationName === '' &&
        values.orders[i].medicationStrength === '' &&
        i !== 0
      ) {
        setValidStep(true);
        return;
      } else if (
        values.orders[i].medicationName !== '' &&
        values.orders[i].medicationStrength === ''
      ) {
        setValidStep(false);
        return;
      } else {
        setValidStep(true);
      }
      if (rcsExtraFields) {
        const isRcsValid = values.patient.rcs.programEnrolled === false;
        // Avoid setting state if it doesn't change
        setValidStep((prevValidStep) => {
          if (isRcsValid !== prevValidStep) {
            return isRcsValid;
          }
          return prevValidStep; // Avoid updating if no change
        });
        return;
      }
    }
  }, [
    medicationNum,
    values.orders,
    values.patient.rcs.programEnrolled,
    rcsExtraFields,
    setValidStep,
  ]);
  useEffect(() => {
    requiredCheck();
  }, [values.orders, requiredCheck]);
  const handleMedicationChange = useCallback(
    (value: string | null, index: number) => {
      medCosts![index] = 'Select Medication & Strength';
      const selectedMed = medicationsList!.find(
        (item) =>
          item.name ===
          (value as string)?.slice(0, (value as string).indexOf(' ('))
      );
      setValues((prev) => ({
        ...prev,
        orders: prev.orders.map((order, i) =>
          i === index
            ? {
                ...order,
                medicationName: value as string,
              }
            : order
        ),
      }));
      currentMedName.current = selectedMed?.name;
      if (rcsExtraFields !== true && selectedMed) {
        setRcsExtraFields(
          selectedMed ? rcsGroup1.includes(selectedMed.name) && !isPAP : false
        );
      }
      setMedicationStrengths((prev) => {
        const newStrengths = [...prev];
        newStrengths[index] = selectedMed?.options || null;
        return newStrengths;
      });
      setMedicationDetailsText((prev) => {
        const newDetails = [...prev];
        if (selectedMed?.brand && isPAP) {
          newDetails[index] =
            'Once your healthcare provider complete our simple steps, excpet to get your medication in about 3 weeks';
        } else if (!selectedMed?.brand && isPAP) {
          newDetails[index] =
            'Once your healthcare provider complete our simple steps, excpet to get your medication in about 1 weeks';
        } else if (selectedMed?.brand && !isPAP) {
          newDetails[index] =
            'Detailed instructions on how to purchase at your prefered pharmacy will be email to you soon';
        }
        return newDetails;
      });
      setIsFirstTextFieldChanged((prev) => {
        const newIsFirstTextFieldChanged = [...prev];
        newIsFirstTextFieldChanged[index] = true;
        return newIsFirstTextFieldChanged;
      });
      if (selectedMed && selectedMed.brand && isPAP) {
        let isntEligible = false;
        if (selectedMed.name === 'Xolair') {
          Number(values.patient.income) < 150000
            ? (isntEligible = false)
            : (isntEligible = true);
        } else {
          isntEligible = !checkEligibilty(clientFPL, selectedMed.name);
        }

        if (isntEligible) {
          setOpenEligibilityDialog(isntEligible);
          setValues((prev) => ({
            ...prev,
            orders: [
              {
                ...prev.orders[index],
                medicationName: '',
              },
              ...prev.orders.slice(1),
            ],
          }));
          setMedicationStrengths((prev) => {
            const newStrengths = [...prev];
            newStrengths[index] = null;
            return newStrengths;
          });
        }
      }
    },
    [
      clientFPL,
      isPAP,
      medCosts,
      medicationsList,
      rcsExtraFields,
      setValues,
      values.patient.income,
    ]
  );
  const handleStrengthChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index: number) => {
      const selectedStrength = e.target.value;
      setValues((prev) => ({
        ...prev,
        orders: prev.orders.map((order, i) =>
          i === index
            ? {
                ...order,
                medicationStrength: selectedStrength,
              }
            : order
        ),
      }));
      setMedCosts((prev) => {
        const newCosts = [...(prev ?? [])];
        newCosts[index] = getMedCost(
          values.orders[index].medicationName.slice(
            0,
            values.orders[index].medicationName.indexOf(' (')
          ),
          selectedStrength,
          isPAP
        );
        return newCosts;
      });
    },
    [setValues, getMedCost, values.orders, isPAP]
  );
  const handleRCSChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setValues((prev) => ({
        ...prev,
        patient: {
          ...prev.patient,
          rcs: {
            ...prev.patient.rcs,
            [name]: value === 'yes',
          },
        },
      }));
    },
    [setValues]
  );
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const medName = queryParams.get('medicationName');
    if (medName && !loadedParams.current) {
      handleMedicationChange(medName, 0);
      loadedParams.current = true;
    }
  }, [handleMedicationChange]);

  return (
    <>
      <Box className="tprx-card">
        <Stack>
          <Typography
            variant="h2"
            textAlign={'center'}
            color={theme.palette.primary.main}>
            Great, now pick the medication you need{' '}
          </Typography>
          <Typography variant="h3" textAlign={'center'}>
            Based on your earlier details, we'll show you your plan options and
            costs - or even if it's free!
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          {medicationNum > 0 &&
            selectMedicationList &&
            Array.from({ length: medicationNum }, (_, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    disablePortal
                    options={selectMedicationList}
                    getOptionLabel={(option) => option as string}
                    value={values.orders[index]['medicationName'] || null}
                    onChange={(_e, value) => {
                      handleMedicationChange(value as string | null, index);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={index === 0}
                        fullWidth
                        name={'medicationName'}
                        value={values.orders[index]['medicationName'] || null}
                        label={'Select Medication'}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required={isFirstTextFieldChanged[index]}
                    key={`choosestrength-${index}`}
                    fullWidth
                    select
                    name={`medicationStrength`}
                    value={
                      medicationStrengths[index]?.some(
                        (option) =>
                          option.strength ===
                          values.orders[index]['medicationStrength']
                      )
                        ? values.orders[index]['medicationStrength']
                        : ''
                    }
                    label={'Choose Medication Strength'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleStrengthChange(e, index);
                    }}
                    variant="outlined">
                    {medicationStrengths[index] ? (
                      medicationStrengths[index]!.map((option) => (
                        <MenuItem key={option.strength} value={option.strength}>
                          {option.strength}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={''}>Choose Medication First</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack justifyContent={'center'} height={'100%'}>
                    <Typography fontWeight={600}>
                      {medCosts &&
                      medCosts[index] === 'Read Full Cost Estimation' ? (
                        <Link
                          onClick={() => {
                            handleClickOpen('cost');
                          }}
                          style={{ cursor: 'pointer' }}>
                          {medCosts[index]}
                        </Link>
                      ) : (
                        (medCosts && medCosts[index]) ||
                        'Select Medication & Strength'
                      )}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color={'textSecondary'}
                    fontWeight={500}>
                    {medicationDetailsText[index]}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}
        </Grid>
        <Button
          sx={{ marginRight: 'auto' }}
          variant="outlined"
          onClick={() => {
            setMedicationNum((prev) => prev + 1);
            values.orders.push({
              doctorName: '',
              medicationName: '',
              medicationStrength: '',
            });
          }}>
          Add Medication
        </Button>
        {medicationDialogData && (
          <CostDialog
            key={medicationDialogData.name}
            open={openCostDialog}
            handleClose={handleClose}
            medicationData={medicationDialogData}
          />
        )}
      </Box>
      {rcsExtraFields && (
        <Box className="tprx-card">
          <Stack
            mr={'auto'}
            spacing={2}
            maxWidth={{
              xs: '100%',
              sm: '80%',
              md: '60%',
            }}>
            <FormControl required>
              <FormLabel id="programEnrolled">
                Are you enrolled in any state or federally funded programs,
                including, but not limited to Medicare (including Medicare Part
                D), Medicaid, Medigap, VA, DOD, TriCare or CHIP?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="programEnrolled"
                value={
                  values.patient.rcs.programEnrolled === true ? 'yes' : 'no'
                }
                name="programEnrolled"
                sx={{ mt: 2 }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleRCSChange(e);
                  if (e.target.value === 'yes') {
                    setOpenEligibilityDialog(true);
                    setValidStep(false);
                  }
                }}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Box>
      )}
      <QualificationDialog
        open={openEligibilityDialog}
        handleClose={handleClose}
        qulificationGroup={
          rcsExtraFields
            ? 'Interested in RCS but have a state / federal funded program'
            : 'who are under 18'
        }
        isEligible={rcsExtraFields ? false : true}
        isRCS={rcsExtraFields}
        medName={currentMedName.current || ''}
      />
    </>
  );
}
export default SecondStep;
